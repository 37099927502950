import React from "react";
import { useState, useEffect } from "react";
import ReactMapGL, {
  Marker,
  NavigationControl,
  Popup,
  FlyToInterpolator,
} from "react-map-gl";
import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it
import { easeCubic } from "d3-ease";
// eslint-disable-next-line import/no-webpack-loader-syntax
/* eslint import/no-webpack-loader-syntax: off */

mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

function MapKecil({ detailObject }) {
  //   console.log(detailObject, "detailObject");
  const [viewport, setViewport] = useState({
    width: "100%",
    height: "93.5%",
    latitude: Number(detailObject.latitude),
    longitude: Number(detailObject.longitude),
    zoom: 12,
    // pitch: 50,
  });

  const [selectedLokasi, setSelectedLokasi] = useState(false);
  const [jalan, setJalan] = useState(false);

  useEffect(() => {
    const listener = (e) => {
      if (e.key === "Escape") {
        setSelectedLokasi(false);
      }
    };

    const handleClick = (e) => {
      // console.log(e.path, "event");
      // if (e.path[1].className === "detail") {
      //   setJalan(true);
      // }
    };

    window.addEventListener("keydown", listener);
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("keydown", listener);
      window.removeEventListener("click", handleClick);
    };
  }, []);

  // Function toLokasi
  const toLokasi = () => {
    setViewport({
      ...viewport,
      longitude: Number(detailObject.longitude),
      latitude: Number(detailObject.latitude),
      zoom: 14,
      transitionDuration: 4000,
      transitionInterpolator: new FlyToInterpolator(),
      transitionEasing: easeCubic,
    });
  };

  if (jalan) {
    toLokasi();
    setJalan(false);
  }

  return (
    <ReactMapGL
      {...viewport}
      // mapStyle="mapbox://styles/mapbox/satellite-v9"
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      onViewportChange={(newView) => setViewport(newView)}
    >
      <NavigationControl showCompass={false} className="navigation-control" />
      {detailObject && (
        <Marker
          latitude={Number(detailObject.latitude)}
          longitude={Number(detailObject.longitude)}
          offsetTop={(-viewport.zoom * 10) / 2}
          offsetLeft={(-viewport.zoom * 5) / 2}
        >
          <button
            onClick={(e) => {
              e.preventDefault();
              setSelectedLokasi(true);
            }}
            className="marker-btn"
            style={{ background: "none", border: "none", cursor: "pointer" }}
          >
            <img
              src="map.png"
              alt="finder map"
              width={viewport.zoom * 5}
              height={viewport.zoom * 5}
            />
          </button>
        </Marker>
      )}
      {selectedLokasi ? (
        <Popup
          latitude={Number(detailObject.latitude)}
          longitude={Number(detailObject.longitude)}
          onClose={() => setSelectedLokasi(false)}
        >
          <div style={{ width: "130px" }}>
            <h2 style={{ fontSize: "12px" }}>{detailObject.kegiatan}</h2>
            <span style={{ fontSize: "8px" }}>
              {detailObject.desc_kegiatan
                ? detailObject.desc_kegiatan
                : "Berikut ini deskripsi kegiatan yang sedang dilaksanakan oleh CT Arsa"}
            </span>
            <img
              src={
                detailObject.foto_aktivitas.length > 0
                  ? detailObject.foto_aktivitas[0].link_foto_agenda
                  : "https://foto2.data.kemdikbud.go.id/getImage/69986890/1.jpg"
              }
              alt=""
              width="100%"
              height="75px"
              style={{ objectFit: "cover", marginTop: "7px" }}
            />
          </div>
        </Popup>
      ) : null}
    </ReactMapGL>
  );
}

export default MapKecil;
