import { useState } from "react";
import "./detail.css";
// import { formatDate } from "../helpers/formatDate";

function Detail({ detailAgenda, setDetailObject }) {
  // console.log(detailAgenda, "<<detail agneda");
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = (detail) => {
    // console.log(detail, "detail");
    setDetailObject(detail);
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="detail" onClick={() => handleOpen(detailAgenda)}>
        <h3>
          {detailAgenda.kegiatan}
          {detailAgenda.new_info && <span>Baru!</span>}
        </h3>
      </div>
      {/* {isOpen && (
        <div className="detail-desc">
          <span className="timeStamp">{formatDate(detailAgenda.tanggal)}</span>
          <p>{detailAgenda.desc_kegiatan}</p>
          <span className="fullArticle">
            <span>
              <i>Kategori:</i> {detailAgenda.type_agenda}
            </span>
          </span>
        </div>
      )} */}
    </>
  );
}

export default Detail;
