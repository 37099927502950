// import CloudChart from "../cloudChart/CloudChart";
// import { Phone } from "@material-ui/icons";
import axios from "axios";
import { useEffect, useState } from "react";
import "./topics.css";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/custom-animations/cube-animation.css";

function Topics({ dataQuery }) {
  const [quotes, setQuotes] = useState([]);

  useEffect(() => {
    const fetcQuotes = async () => {
      const dataQuotes = await axios.get(
        "https://apictarsa.tms.my.id/api/quotes"
      );
      setQuotes(dataQuotes.data);
    };

    fetcQuotes();
  }, []);

  return (
    <div className="topics">
      <div className="topic-header">
        <span className="cloudTabSelected">QUOTES</span>
      </div>
      <div className="topic-cloud">
        {/* <CloudChart dataQuery={dataQuery} /> */}
        {/* <article>
          "Sisihkan hartamu <span>untuk orang yang membutuhkan!"</span>
        </article>
        <p>
          <Phone /> 021-791-77-088 / +62 823 0823 9712
        </p> */}

        {/* Quotes */}
        {quotes && (
          <AwesomeSlider
            animation="cubeAnimation"
            bullets={false}
            organicArrows={true} // panah
            fillParent={true} // style ikut parent
          >
            {quotes.map((quote) => (
              <div
                className="wrapper-quote"
                key={quote.id_quote}
                style={{ background: "linear-gradient(#fff, #dbedf9)" }}
              >
                <q>{quote.quote}</q>
                <p>Sumber: {quote.sumber}</p>
              </div>
            ))}
          </AwesomeSlider>
        )}
      </div>
    </div>
  );
}

export default Topics;
