import { LinearProgress } from "@material-ui/core";
import Detail from "../detail/Detail";
import "./details.css";
import { formatDate } from "../helpers/formatDate";

function Details({
  isLoading,
  agendas,
  typeAgenda,
  setTypeAgenda,
  setDetailObject,
  detailObject,
  setQuery,
  setTanggal,
}) {
  const handleClickType = (type) => {
    setDetailObject(null);
    setTanggal("");
    setQuery("");

    switch (type) {
      case "all":
        setTypeAgenda("all");
        break;
      case "ramadhan":
        setTypeAgenda("ramadhan");
        break;
      case "literasi":
        setTypeAgenda("literasi");
        break;
      case "event":
        setTypeAgenda("event");
        break;
      case "kesehatan":
        setTypeAgenda("kesehatan");
        break;
      case "sekolah":
        setTypeAgenda("sekolah");
        break;
      case "komunitas":
        setTypeAgenda("komunitas");
        break;
      case "bencana":
        setTypeAgenda("bencana");
        break;
      // case "unit":
      //   setTypeAgenda("unit");
      //   break;
      case "gebrag":
        setTypeAgenda("gebrag");
        break;
      case "mitra":
        setTypeAgenda("mitra");
        break;
      default:
        setTypeAgenda("all");
        break;
    }
  };
  return (
    <div className="details">
      <div className="details-title">
        <h2
          className={typeAgenda === "all" || typeAgenda === "" ? "active" : ""}
          onClick={() => handleClickType("all")}
        >
          PROGRAM CTARSA
        </h2>
        <h2
          className={typeAgenda === "ramadhan" ? "active" : ""}
          onClick={() => handleClickType("ramadhan")}
        >
          RAMADHAN
        </h2>
        <h2
          className={typeAgenda === "literasi" ? "active" : ""}
          onClick={() => handleClickType("literasi")}
        >
          LITERASI
        </h2>
        <h2
          className={typeAgenda === "event" ? "active" : ""}
          onClick={() => handleClickType("event")}
        >
          EVENT
        </h2>
        <h2
          className={typeAgenda === "kesehatan" ? "active" : ""}
          onClick={() => handleClickType("kesehatan")}
        >
          KESEHATAN
        </h2>
        <h2
          className={typeAgenda === "sekolah" ? "active" : ""}
          onClick={() => handleClickType("sekolah")}
        >
          SEKOLAH
        </h2>
        <h2
          className={typeAgenda === "komunitas" ? "active" : ""}
          onClick={() => handleClickType("komunitas")}
        >
          KOMUNITAS
        </h2>
        <h2
          className={typeAgenda === "bencana" ? "active" : ""}
          onClick={() => handleClickType("bencana")}
        >
          BENCANA
        </h2>
        {/* <h2
          className={typeAgenda === "unit" ? "active" : ""}
          onClick={() => handleClickType("unit")}
        >
          UNIT
        </h2> */}
        <h2
          className={typeAgenda === "gebrag" ? "active" : ""}
          onClick={() => handleClickType("gebrag")}
        >
          GEBRAG
        </h2>
        {/* <h2
          className={typeAgenda === "mitra" ? "active" : ""}
          onClick={() => handleClickType("mitra")}
        >
          MITRA
        </h2> */}
      </div>
      {/* Card Detail */}
      {isLoading ? (
        <LinearProgress />
      ) : (
        <div className="container-contents">
          <div className="detail-card">
            {agendas.length > 0 ? (
              agendas.map((detailAgenda) => (
                <Detail
                  detailAgenda={detailAgenda}
                  isLoading={isLoading}
                  key={detailAgenda.id_agenda}
                  setDetailObject={setDetailObject}
                />
              ))
            ) : (
              <p>Data pencarian tidak ditemukan!</p>
            )}
          </div>
          {detailObject && (
            <div className="detail-agenda">
              {/* <div className="title-detail-agenda">
                <h3>In Picture: {detailObject.kegiatan}</h3>
              </div> */}
              <div className="content-detail-agenda">
                <h3>Deskripsi</h3>
                <p>{detailObject.kegiatan}</p>
                <div className="detail-desc">
                  <span>{detailObject.desc_kegiatan}</span>
                  <span className="fullArticle">
                    <span>
                      <i>Kategori:</i> {detailObject.type_agenda}
                    </span>
                  </span>
                </div>
                <h3>Alamat</h3>
                <p>{detailObject.alamat}</p>
                <h3>Tanggal</h3>
                <p>{formatDate(detailObject.tanggal)}</p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Details;
